import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StyledDistribution from './StyledDistribution';
import DistributorsTable from '../../table/DistributorsTable';
import {Row, Col, Button} from 'react-bootstrap';
import _ from 'lodash';
import {
  isNewQuoteProject
} from '../../../constants';
import QcProfiles from './QcProfiles';
import QcProfilesForQuote from './QcProfilesForQuote';

class Distribution extends Component {

  handleSelectDistributor = distributorId => {
    const {onSelect, filters} = this.props;
    onSelect('distributor_id', distributorId);
    const distributor = filters.distributors.find(d => d.value === distributorId);
    const distributor_qc_profiles = this.calcDistroSpecificProfileList(distributor);

    if (distributor_qc_profiles && distributor_qc_profiles.length === 1) {
      const isNewQuote = isNewQuoteProject({request_type_id: this.props.data.request_type_id});
      const qcProfileId = distributor_qc_profiles[0].value;
      onSelect('qc_profile_id', isNewQuote ? [{qc_profile_id: qcProfileId}] : qcProfileId);
    }
  };

  componentDidMount() {
    const {
      data: {distributor_id, request_type_id, cs_studio_id},
      onSelect,
      filters: {distributors},
      showOptions
    } = this.props;
    if (!showOptions && isNewQuoteProject({request_type_id}) && cs_studio_id && distributor_id && !distributors.some(d => d.value === distributor_id)) {
      onSelect('distributor_id', undefined);
    }
  }

  renderSelect = () => {
    const {data, filters, onSave} = this.props;
    return (
      <StyledDistribution className="step-body-wrapper distribution-step distributor-select">
        <DistributorsTable
          data={filters.distributors}
          qcProfiles={filters.qc_profiles}
          onSave={onSave}
          onSelect={this.handleSelectDistributor}
          selected={data.distributor_id}
          />
      </StyledDistribution>
    );
  };

  handleLogoLoad = () => {
    this.refs.distributorLogo.className = this.refs.distributorLogo.className.replace('hidden', '').trim();
  };

  handleLogoError = () => {
    this.refs.distributorName.className = this.refs.distributorName.className.replace('hidden', '').trim();
    const distributorInfo = this.refs.distributorName.parentElement.parentElement;
    distributorInfo.className = distributorInfo.className + ' no-logo';
  };

  calcDistroSpecificProfileList = (distributor) => {
    const {filters: {qc_profiles}} = this.props;
    const distributor_qc_profile_ids = distributor.value && distributor.qc_profile_ids && distributor.qc_profile_ids.split(',');
    let distributor_qc_profiles = [];
    if (distributor_qc_profile_ids) {
      distributor_qc_profile_ids.forEach(profileId => {
        let profile = qc_profiles.find(p => p.value === +profileId);
        if (profile) {
          distributor_qc_profiles.push(profile);
        }
      });
      distributor_qc_profiles = _.orderBy(distributor_qc_profiles, [d => d.name.toLowerCase()]);
    }
    return distributor_qc_profiles;
  }

  renderOptions = () => {
    const {data, filters, onClickChange, onSelect} = this.props;
    const {qc_profiles, qc_types, distributors} = filters;
    const distributor = distributors.find(d => d.value === data.distributor_id) || {};
    const distributor_qc_profiles = this.calcDistroSpecificProfileList(distributor);

    const QcProfilesComponent = isNewQuoteProject(data) ? QcProfilesForQuote : QcProfiles;

    return (
      <StyledDistribution className="step-body-wrapper distribution-step distributor-options">
        <div className="distributor-top">
          <Row className="distributor-select-block">
            <Col md={12} xs={12}>
              <div className="distributor-select-text">Select Distributor</div>
            </Col>
          </Row>
          <Row className={`distributor-selected-info${distributor.value && !distributor.logo_url ? ' no-logo' : ''}`}>
            <Col className="distributor-current" md={4} xs={12}>
              {
                distributor.logo_url ?
                  <img
                    className="distributor-logo hidden"
                    title={distributor.name}
                    src={distributor.logo_url}
                    alt=""
                    ref="distributorLogo"
                    onLoad={this.handleLogoLoad}
                    onError={this.handleLogoError}
                  /> : null
              }
              <div className={`distributor-name${distributor.logo_url ? ' hidden' : ''}`} ref="distributorName">
                {distributor.name}
                <div className="distributor-website">{distributor.website}</div>
              </div>
            </Col>
            <Col className="distributor-change" md={8} xs={12}>
              <Button bsStyle="primary" onClick={onClickChange}>Change</Button>
            </Col>
          </Row>
        </div>
        <div className="qc-profiles-and-types">
          <QcProfilesComponent
            data={data}
            distributorQcProfiles={distributor_qc_profiles}
            qcProfiles={qc_profiles}
            qcTypes={qc_types}
            onChange={onSelect}
          />
        </div>
      </StyledDistribution>
    );
  };

  render() {
    const {showOptions} = this.props;
    return showOptions ? this.renderOptions() : this.renderSelect();
  }
}

Distribution.propTypes = {
  data: PropTypes.object.isRequired,
  showOptions: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClickChange: PropTypes.func.isRequired
};

Distribution.defaultProps = {
  data: {}
};

export default Distribution;
